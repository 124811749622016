import router from '@/router';
import { setDataList } from "@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils";
import { GET_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';

export default class UserListViewModel {
  constructor() {
    this.userAdminType = '';
    this.searchDataList = [
      {
        title:'검색어',
        type: 'RadioInput',
        dataList:[
          {id:'accountId',text:'회원ID'},
          {id:'name',text:'회원이름'},
        ],
        grid: 100,
        isFull:true,
        value1:'accountId',
        value2:'',
      },
    ]
    this.boardData = {
      title:'회원 검색결과',
      drawDataList: [
        {
          title:'회원 ID',
          width:'210px',
          value:'accountId',
          isAlignLeft: true,
        },
        {
          title:'역할',
          width:'60px',
          value:'role',
          filter:{
            name:'convertIdToText',
            value:'user_role'
          },
          class: {
            classColorFilter:{
              name:'convertIdToColor',
              dataListName:'user_role',
              prev:'fw_bold tc'
            }
          },
          // valueCustom:[
          //   { 
          //     type: 'value',
          //     value: 'role',
          //     filter:{
          //       name:'convertIdToText',
          //       value:'user_role'
          //     },
          //   },
          //   {
          //     type: 'value',
          //     value:'is_family_leader',
          //     filter:{
          //       name:'convertIdToText',
          //       value:'user_family_leader'
          //     },
          //   },
          // ],
        },
        {
          title:'회원이름',
          width:'',
          value:'name',
          isAlignLeft: true,
        },
        // {
        //   title:'email',
        //   width:'',
        //   value:'email',
        //   isEllip: true,
        //   isAlignLeft: true
        // },
        {
          title:'휴대전화 번호',
          width:'124px',
          value:'phoneNumber',
          filter: { name:'convertPhoneNember' },
          isMobileTitle:true
        },
        {
          title:'등록 일시',
          width:'160px',
          value:'registeredAt',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
          isMobileTitle:true
        },
        {
          title:'계정상태',
          width:'100px',
          value:'status',
          filter:{
            name:'convertIdToText',
            value:'user_status_name'
          },
          badge: {
            badgeColorFilter:{
              name:'convertIdToColor',
              dataListName:'user_status_name'
            },
            badgeFill: false
          },
        },
      ],
      option:{
        isTotal: true,
        isSize: true,
        // rowIdValue: 'walletId'
      },
      // markData:[
      //   {
      //     id : this.searchDataList[0].value1,
      //     value : this.searchDataList[0].value2
      //   }
      // ]
    };
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
  }
  init(){
    this.getData();
  }
  onSearch(){
    this.getData();
  }
  onClickRow(rowId){
    router.push({ name: `SERVICE_USER_${this.userAdminType}_DETAIL`, params: { id : rowId } });
  }
  getData(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.USER_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.dataList = resultData.list;
      this.paginationData.totalCount = resultData.total;
      this.paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}